import React from 'react'
import team1 from '../img/team/team-1.jpg'
import team2 from '../img/team/team-2.jpg'
import team3 from '../img/team/team-3.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import netsrishtilogo from '../img/watermarkCompanylogo.jpeg'
import { faFacebookF, faTwitter, faInstagram, faDribbble } from '@fortawesome/free-brands-svg-icons';

const Doctors = () => {
  return (
    <div>
        <section class="team spad">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 text-center">
                    <div class="section-title">
                        <span>Our Team</span>
                        <h2>Our Expert Doctors</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                {/* <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="team__item">
                        <img src={team1} alt=""/>
                        <h5>Dr Nikitha</h5>
                        <span>Eye surgeon</span>
                        <div class="team__item__social">
                            <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
                            <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
                            <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
                            <a href="#"><FontAwesomeIcon icon={faDribbble}/></a>
                        </div>
                    </div>
                </div> */}
                <div class="col-lg col-md-12 col-sm-6">
                    <div class="team__item">
                        <img src={team1} alt=""/>
                        <h5>Dr Nikitha</h5>
                        {/* <span>Plastic surgeon</span>
                        <div class="team__item__social">
                            <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
                            <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
                            <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
                            <a href="#"><FontAwesomeIcon icon={faDribbble}/></a>
                        </div> */}
                    </div>
                </div>
                {/* <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="team__item">
                        <img src={team3} alt=""/>
                        <h5>Nathan Mullins</h5>
                        <span>Plastic surgeon</span>
                        <div class="team__item__social">
                            <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
                            <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
                            <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
                            <a href="#"><FontAwesomeIcon icon={faDribbble}/></a>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </section>
    </div>
  )
}

export default Doctors
