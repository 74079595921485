import React, { useState } from 'react'
// import { faFacebookF, faTwitter, faInstagram, faDribbble } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMapMarker, faClock, faLeaf, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../img/logo.png'
import kruthikalogo from '../img/nikithaeyelogo.png'
import { Drawer, IconButton, List, ListItem, ListItemText } from '@mui/material';

const Header = ({content,setcontent}) => {
  const [draweropen,setdraweropen]=useState(false)

  return (
    <div>
    <div className="offcanvas-menu-wrapper">
      <div className="offcanvas__logo">
        <a href="./index.html"><img src={logo} alt="Logo" /></a>
      </div>
      <div id="mobile-menu-wrap"></div>
      <div className="offcanvas__btn">
        <a  className="primary-btn"  >Appointment</a>
      </div>
      <ul className="offcanvas__widget">
        <li><FontAwesomeIcon icon={faPhone} /> 7892159394</li>
        <li><FontAwesomeIcon icon={faMapMarker} /> Bangalore</li>
        <li><FontAwesomeIcon icon={faClock} />Mon to Sat 6:30am to 8:30pm</li>
      </ul>
      {/* <div className="offcanvas__social">
        <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
        <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
        <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
        <a href="#"><FontAwesomeIcon icon={faDribbble}/></a>
      </div> */}
    </div>
    {/* Offcanvas Menu End */}

    {/* Header Section Begin */}
    <header className="header">
      <div className="header__top">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <ul className="header__top__left">
                <li><FontAwesomeIcon icon={faPhone}/><b>7892159394</b></li>
                <li><FontAwesomeIcon icon={faMapMarker} /> <b>Bangalore</b></li>
                <li><FontAwesomeIcon icon={faClock} /><b>Mon to Sat 6:30am to 8:30pm</b></li>
              </ul>
            </div>
            {/* <div className="col-lg-4">
              <div className="header__top__right">
              <a href="#"><FontAwesomeIcon icon={faFacebookF}/></a>
              <a href="#"><FontAwesomeIcon icon={faTwitter}/></a>
              <a href="#"><FontAwesomeIcon icon={faInstagram}/></a>
              <a href="#"><FontAwesomeIcon icon={faDribbble}/></a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div style={{
        width:'100%'
      }}>
        <div className="row navrow">
        <div style={{
          width:'fit-content',
          height:'fit-content'
        }}>
                <img src={kruthikalogo} className='kruthikalogo'/>
            </div>

            <div className="header__menu__option" style={{
              padding:'0'
            }}>
              <nav className="header__menu">
                <ul>
                  <li className={content=='home' ?'active':''} onClick={()=>{setcontent('home')}}><a><b>Home</b></a></li>
                  <li className={content=='about' ?'active':''}onClick={()=>{setcontent('about')}}><a><b>About</b></a></li>
                  <li className={content=='services' ?'active':''}onClick={()=>{setcontent('services')}}><a><b>Our Services</b></a></li>
                  {/* <li><a href="#"><b>Services</b></a></li>
                  <li><a href="#"><b>Pages</b></a>
                    <ul className="dropdown">
                      <li><a href="#">Pricing</a></li>
                      <li><a href="#">Doctor</a></li>
                      <li><a href="#">Blog Details</a></li>
                    </ul>
                  </li>
                  <li><a href="#"><b>News</b></a></li> */}
                  <li className={content=='contact' ?'active':''}  onClick={()=>{setcontent('contact')}}><a><b>Contact</b></a></li>
                </ul>
              </nav>
              <div className="header__btn">
              <a  className="primary-btn" href='http://lafi.netsrishti.net/'><b>Appointment</b></a>
              </div>
            </div>
        </div>
        <div className="canvas__open" onClick={()=>setdraweropen(true)}>
          <i className="fa fa-bars"></i>
        </div>
        <Drawer open={draweropen} onClose={()=>setdraweropen(false)}>
            <div style={{ width: 250 }}>
              <IconButton onClick={()=>setdraweropen(false)} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <FontAwesomeIcon icon={faTimes} />
              </IconButton>
              <List>
                <ListItem button onClick={() => { setcontent('home'); setdraweropen(false); }}>
                  <ListItemText primary="Home" />
                </ListItem>
                <ListItem button onClick={() => { setcontent('about'); setdraweropen(false); }}>
                  <ListItemText primary="About Us" />
                </ListItem>
                <ListItem button onClick={() => { setcontent('services'); setdraweropen(false); }}>
                  <ListItemText primary="Our Services" />
                </ListItem>
                <ListItem button onClick={() => { setcontent('contact'); setdraweropen(false); }}>
                  <ListItemText primary="Contact" />
                </ListItem>
                <ListItem button component="a" href="http://lafi.netsrishti.net/" style={{color:'black'}}>
                  <ListItemText primary="Appointment" />
                </ListItem>
              </List>
            </div>
          </Drawer>
      </div>
    </header>
    </div>
  )
}

export default Header
