import React from 'react'
import { faFacebookF, faTwitter, faInstagram, faDribbble } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faMapMarker, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import netsrishtiwatermarklogo from '../img/watermarkCompanylogo.jpeg'
import krithikalogo from '../img/kruthikafooter.png'
import netsrishtifooterlogo from '../img/netsrishtifooterlogo.png'

const Footer = ({content,setcontent}) => {
  return (
    <div>
          <footer class="footer">
        <div class="footer__top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-4">
                        <div class="footer__logo">
                            <a href="#"><img src={krithikalogo} style={{width:'15rem'}} alt=""/></a>
                        </div>
                    </div>
                    {/* <div class="col-lg-4 col-md-8">
                        <div class="footer__newslatter">
                            <form action="#">
                                <input type="text" placeholder="Email"/>
                                <button type="submit" class="site-btn">Subscribe</button>
                            </form>
                        </div>
                    </div> */}
                    {/* <div class="col-lg-4 col-md-12">
                        <div class="footer__social">
                            <a href="#"><FontAwesomeIcon icon={faFacebookF} style={{display:'flex',paddingLeft:'19px'}}/></a>
                            <a href="#"><FontAwesomeIcon icon={faTwitter} style={{display:'flex',paddingLeft:'19px'}}/></a>
                            <a href="#"><FontAwesomeIcon icon={faInstagram} style={{display:'flex',paddingLeft:'19px'}}/></a>
                            <a href="#"><FontAwesomeIcon icon={faDribbble} style={{display:'flex',paddingLeft:'19px'}}/></a>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-2 col-md-3 col-sm-6">
                    <div class="footer__widget">
                        <h5>Company</h5>
                        <ul>
                            <li className='d-flex' onClick={()=>setcontent('about')} style={{cursor:'pointer'}}><a>About Us</a></li>
                            <li className='d-flex'><a href="http://lafi.netsrishti.net">Book Appointment</a></li>
                        </ul>
                    </div>
                </div>
                {/* <div class="col-lg-2 col-md-3 col-sm-6">
                    <div class="footer__widget">
                        <h5>Quick links</h5>
                        <ul>
                            <li className='d-flex'><a href="#">Facial Fillers</a></li>
                            <li className='d-flex'><a href="#">Breast Surgery</a></li>
                            <li className='d-flex'><a href="#">Body Lifts</a></li>
                            <li className='d-flex'><a href="#">Face & Neck</a></li>
                            <li className='d-flex'><a href="#">Fat Reduction</a></li>
                        </ul>
                    </div>
                </div> */}
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="footer__address">
                        <h5>Contact Us</h5>
                        <ul>
                            <li className='d-flex'><i class="fa fa-map-marker"></i> Bangalore</li>
                            <li className='d-flex'><i class="fa fa-phone"></i>7892159394</li>
                            <li className='d-flex'><i class="fa fa-envelope"></i>kruthikaeyehospital@gmail.com</li>
                            <li className='d-flex'><i class="fa fa-clock"></i>Mon to Sat 6:30am to 8:30pm</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5 col-md-12 col-sm-6">
                    <div class="footer__map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.4958140332296!2d77.55539026888368!3d13.067732784626113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae235a03002ea3%3A0x22f428969963b8c0!2sKRUTHIKA%20EYE%20HOSPITAL!5e0!3m2!1sen!2sin!4v1725945871281!5m2!1sen!2sin"
                        height="210"
                        style={{ border: 0 }}
                        allowFullScreen
                    >
                    </iframe>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer__copyright" style={{
            position: 'relative'
        }}>
                    <div class='netsrishti-logo'>
                        <img src={netsrishtifooterlogo} alt='' style={{height:'2.5rem',width:'10rem'}}/>
                    </div>            

        </div>
        <ul class='credits'>
                            <li>All Rights Reserved</li>
                            <li>Terms & Use</li>
                            <li>Privacy Policy</li>
                </ul>
    </footer>
    </div>
  )
}

export default Footer
