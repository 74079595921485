import React, { useEffect, useState } from 'react'
import ScrollReveal from 'scrollreveal';
import '../css/style.css'
import { Container } from '../StyledCss';
import team1 from '../img/team/team-1.jpg'
import team2 from '../img/team/team-2.jpg'
import team3 from '../img/team/team-3.jpg'
import Header from './Header';
import Footer from './Footer';
import Doctors from './Doctors';
import Home from './Home';
import About from './About';
import Contact from './Contact';
import Services from './Services';


const LandingPage = () => {

    const [content,setcontent]=useState('home')

    useEffect(()=>{
        ScrollReveal({ reset: true, distance: '60px', duration: 2500, delay: 400 })
        ScrollReveal().reveal('.hero__text', { delay: 200 });
        ScrollReveal().reveal('.section-title', { delay: 200, origin: 'top' });
        ScrollReveal().reveal('.team__item', { delay: 200, origin: 'bottom' });
        ScrollReveal().reveal('.chooseus__item', { delay: 200, origin: 'bottom' });
        ScrollReveal().reveal('.services__item', { delay: 200, origin: 'left' });
        ScrollReveal().reveal('.services__itemedit', { delay: 200, origin: 'right' });
        ScrollReveal().reveal('.latest__item', { delay: 200, origin: 'left' });
        ScrollReveal().reveal('.latest__item2', { delay: 200, origin: 'bottom' });
        ScrollReveal().reveal('.latest__item3', { delay: 200, origin: 'right' });
    },[content])

    const teamimges=[team1,team2,team3]
   
  return (
    <Container>
    <Header content={content} setcontent={setcontent}/>
    {content=='about' ?(
        <About content={content} setcontent={setcontent}/>
    ): content=='contact'?(
        <Contact content={content} setcontent={setcontent}/>
    ):content=='services' ?(
        <>
        <Services content={content} setcontent={setcontent}/>
        </>
    ):(
        <>
        <Home content={content} setcontent={setcontent}/>
        <Doctors content={content} setcontent={setcontent}/>
    </>
    )}
    <Footer content={content} setcontent={setcontent}/>
  </Container>
  )
}

export default LandingPage
