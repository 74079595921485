import React from 'react'
// import servicebg from '../img/services-bg.jpg'
import laafilogo from '../img/logo-icon.png'
// import certificateicon from '../img/icons/certificate-icon.svg'
// import doctoricon from '../img/icons/doctor-icon.svg'
// import equpmenticon from '../img/icons/equipment-icon.svg'
import { Button } from '@mui/material';
import eyeimage from '../img/homebackgroundimg.jpg'
import vedio_tumb from '../img/vedio_tump_img.jpeg'
import patientcare from '../img/icons/patiemtcare-icon.svg'
import opthalmology from '../img/icons/opthalmology.svg'
import affordable from '../img/icons/affordable.svg'
import Services from './Services'

const Home = ({content,setcontent}) => {
  return (
    <div>
      <section className="hero spad set-bg" style={{backgroundImage:`url(${eyeimage})`}}>
      <div className="row">
        <div className="hero__text">
              {/* <span>Eiusmod tempor incididunt</span> */}
              <h2>See Clearly,</h2>
              <h2>Live Fully.</h2>
              <button  className="primary-btn normal-btn" onClick={()=>{setcontent('contact')}}>Contact us</button>
            </div>
        </div>
    </section>
    {/* Hero Section End */}

    {/* Consultation Section Begin */}
    <section className="consultation">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="consultation__form">
              <div className="section-title">
                <span>REQUEST FOR YOUR</span>
                <h2>Consultation</h2>
              </div>
              <form >
                <input type="text" placeholder="Name" />
                <input type="text" placeholder="Email" />
                <div className="datepicker__item">
                  <input type="text" placeholder="Date" className="datepicker" />
                  <i className="fa fa-calendar"></i>
                </div>
                {/* <select>
                  <option value="">Type of service</option>
                  <option value="">Advanced equipment</option>
                  <option value="">Qualified doctors</option>
                  <option value="">Certified services</option>
                  <option value="">Emergency care</option>
                </select> */}
                 <a  className="site-btn" href='http://lafi.netsrishti.net/'>Book appointment</a>
                {/* <button type="submit" className="site-btn" onClick={() => window.location.href = 'http://lafi.netsrishti.net'} >Book appointment</button> */}
              </form>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="consultation__text">
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="consultation__text__item">
                    <div className="section-title">
                      {/* <span>Welcome to Kruthika</span> */}
                      <h4 >Welcome to  <b style={{color:'#13a2b7'}}> Kruthika</b></h4>
                    </div>
                    <p>At the heart of the community, Kruthika Eye Hospital offers a blend of cutting-edge technology and compassionate care to ensure optimal eye health for all patients.</p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="consultation__video set-bg" style={{backgroundImage:`url(${vedio_tumb})`}}>
                    {/* <a href="" className="play-btn video-popup"><i className="fa fa-play d-flex justify-content-center"></i></a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Consultation Section End */}

    {/* Choose Us Section Begin */}
    <section className="chooseus spad" >
      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center">
            <div className="section-title">
              <span>Why choose us?</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={patientcare} alt=""/>
              <h5>Compassionate and personalized patient care</h5>
              <p>We believe that each person we encounter should be valued and treated with dignity, courtesy and respect. We also strive to educate our patient on complex eye problems. By providing clear instructions and educating our patients, we empower them to make confident decisions about their healthcare.</p>
              <Button variant='contained' className='mt-5' onClick={() => window.location.href = 'http://lafi.netsrishti.net'}> book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={opthalmology} alt=""/>
              <h5>State of the art ophthalmology by experts</h5>
              <p>Our ophthalmologists use the latest technologies to treat serious eye diseases. We aim to provide technical excellence and cutting-edge technology, which enables our doctors to perform complex surgeries with increased precision, control, and safety, resulting in faster recovery and comfort for our patients. We take pride in using the best technology available for all our patients.</p>
              <Button variant='contained' className='mt-5'onClick={() => window.location.href = 'http://lafi.netsrishti.net'} > book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={affordable} alt=""/>
              <h5>Comprehensive quality  eye care  at affordable costs</h5>
              <p>Because we have an ophthalmologist and trained optometrists on our team, we are able to offer every optical service under one roof. From routine eye exams to advanced eye surgery, our eye care professionals possess the training and technology to care for the diverse needs of our patients. We believe every single one of us deserves access to quality, affordable health care at pocket-friendly prices.</p>
              <Button variant='contained' className='mt-5'  onClick={() => window.location.href = 'http://lafi.netsrishti.net'}> book now</Button>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6">
            <div className="chooseus__item">
              <img src={laafilogo} alt="" className='laafi_logo'/>
              <h5>Laafi</h5>
              <p>Laafi is used to schedule appointments, help patients and doctors keep track of health records and more</p>
              <Button variant='contained' className='mt-5' onClick={() => window.location.href = 'http://lafi.netsrishti.net'}> book now</Button>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* Choose Us Section End */}

    {/* Services Section Begin */}
    {/* <section className="services spad"style={{
          backgroundImage: `url(${servicebg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}>
      <div className="container">
      <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-6">
                    <div class="section-title">
                        <span>Our services</span>
                        <h2 style={{display:'flex', justifyContent:'flex-start'}}>Offer for you</h2>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="services__btn">
                        <a class="primary-btn" onClick={()=>{setcontent('contact')}}>Contact us</a>
                    </div>
                </div>
        </div> */}
        <Services content={content} setcontent={setcontent}/>
        {/* <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="services__item">
              <div className="services__item__icon">
                <i className="fa fa-eye"></i>
              </div>
              <div class="services__item__text">
                <h5>Cataract Surgery</h5>
                <p>Our eye clinic provides expert eye surgery with advanced techniques and personalized care.</p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="services__itemedit">
              <div className="services__item__icon">
                <i className="fa fa-glasses"></i>
              </div>
              <div class="services__item__text">
                <h5>Vision Correction</h5>
                <p>Our eye clinic specializes in tailored vision correction solutions to improve your sight.</p>
               </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="services__item">
              <div className="services__item__icon">
                <i className="fa fa-eye-dropper"></i>
              </div>
              <div class="services__item__text">
                <h5>Eye Care</h5>
                <p>We offer comprehensive eye care to ensure your vision stays clear and healthy.</p>
              </div>
            </div>
          </div>
        </div> */}
      {/* </div>
    </section> */}
    </div>
  )
}

export default Home
