import React from 'react'
import cataract from '../img/services images/cataract.jpeg'
import refractive from '../img/services images/refractive surgery.jpeg'
import vetrioratinal from '../img/services images/vetricunol.jpeg'
import diabetic from '../img/services images/diabetic.jpeg'
import glucoma from '../img/services images/glucoma.jpeg'
import dryeye from '../img/services images/dryeye.jpeg'
import servicebg from '../img/services-bg.jpg'

const Services = ({setcontent}) => {
  console.log(setcontent,' console.log(setcontent);');
  return (
    <div style={{
      backgroundImage: `url(${servicebg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
      <section className="services spad" style={{background: 'rgba(0, 0, 0, 0.5)'}}>
      <div className="container">
      <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-6">
                    <div class="section-title">
                        <span>Our services</span>
                        <h2 style={{display:'flex', justifyContent:'flex-start'}}>Services</h2>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-6">
                    <div class="services__btn">
                        <button class="primary-btn" onClick={()=>{setcontent('contact')}} style={{backgroundColor:'transparent'}} >Contact us</button>
                    </div>
                </div>
        </div>
    <div className="row" style={{
      rowGap: '20px'
    }}>
    <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="services__item flip-container">
        <div className="flipper">
          {/* Front side */}
          <div className="front">
            <div className="services__item__icon">
              <img src={cataract} alt=''/>
            </div>
            <div className="services__item__text">
              <h5>Cataract Surgery</h5>
            </div>
          </div>

          {/* Back side */}
          <div className="back">
            <div className="services__item__text">
              <h5>Cataract Surgery</h5>
              <p>At Kruthika Eye Hospital, we specialize in advanced cataract surgery that restores your vision and transforms your life. Our experienced surgeons use state-of-the-art technology to deliver safe, precise, and pain-free procedures.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 col-md-4 col-sm-6">
      <div className="services__item flip-container">
        <div className="flipper">
          {/* Front side */}
          <div className="front">
            <div className="services__item__icon">
              <img src={refractive} alt=''/>
            </div>
            <div className="services__item__text">
              <h5>Refractive Surgery</h5>
            </div>
          </div>

          {/* Back side */}
          <div className="back">
            <div className="services__item__text">
              <h5>Refractive Surgery</h5>
              <p>Tired of glasses or contacts? Kruthika Eye Hospital offers advanced Refractive Surgery to correct vision issues like nearsightedness, farsightedness, and astigmatism. Enjoy clear vision and freedom from eyewear today!</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="col-lg-4 col-md-4 col-sm-6 ">
      <div className="services__item flip-container">
        <div className="flipper">
          {/* Front side */}
          <div className="front">
            <div className="services__item__icon">
              <img src={vetrioratinal} alt=''/>
            </div>
            <div className="services__item__text">
              <h5>Vitreo Retinal Services</h5>
            </div>
          </div>

          {/* Back side */}
          <div className="back">
            <div className="services__item__text">
              <h5>Vitreo Retinal Services</h5>
              <p>Our Vitreo-Retinal Services provide expert diagnosis and advanced treatments using cutting-edge imaging like OCT, FFA, and B-scan to detect retinal and vitreous diseases early.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4 col-md-4 col-sm-6 ">
      <div className="services__item flip-container">
        <div className="flipper">
          {/* Front side */}
          <div className="front">
            <div className="services__item__icon">
                <img src={diabetic} alt=''style={{height:'12rem',width:'12rem'}}/>
            </div>
            <div className="services__item__text">
              <h5>Diabetic retinopathy </h5>
            </div>
          </div>

          {/* Back side */}
          <div className="back">
            <div className="services__item__text">
            <h5>Diabetic retinopathy </h5>
            <p>We provide advanced diagnosis and treatment for diabetic retinopathy, using cutting-edge technology to offer personalized care, including laser surgery, medication, or vitrectomy.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services__item flip-container">
              <div className="flipper">
                  {/* Front side */}
                  <div className="front">
                  <div className="services__item__icon">
                  <img src={glucoma} alt=''/>
                  </div>
                  <div className="services__item__text">
                  <h5>Galucoma</h5>
                  </div>
                  </div>

                  {/* Back side */}
                  <div className="back">
                  <div className="services__item__text">
                  <h5>Galucoma</h5>
                      <p>We specialize in early glaucoma diagnosis and management with advanced tools, offering eye exams, medication, laser therapy, and surgery when needed.</p>
                  </div>
                  </div>
              </div>
              </div>  
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="services__item flip-container">
              <div className="flipper">
                  {/* Front side */}
                  <div className="front">
                  <div className="services__item__icon">
                  <img src={dryeye} alt=''/>
                  </div>
                  <div className="services__item__text">
                  <h5>Dry eye </h5>
                  </div>
                  </div>

                  {/* Back side */}
                  <div className="back">
                  <div className="services__item__text">
                  <h5>Dry eye </h5>
                  <p> 
                  We provide comprehensive dry eye diagnosis and personalized treatments, including artificial tears, prescription drops, lifestyle changes, punctal plugs, and thermal therapies. </p>
                  </div>
                  </div>
              </div>
              </div>  
            </div>
    </div>

{/* 
          <div className="row mt-3">

          </div> */}
          </div>
          </section>
    </div>
  )
}

export default Services
